.button-container {
    margin-bottom: 1vh;
}

.center-button {
    padding: 10px 20px;
    border-radius: 5px;
    background-color: transparent;
    color: #ffbe00;
    border: none;
    outline: none;
    text-align: center;
    text-decoration: none;
    font-size: max(0.8vw, 14px);
    transition-duration: 0.4s;
    width: 100%;
    z-index: 1;
}

.center-button:hover {
    background-color: #ffbe00;
    color: #1e2336;
}

.popup-code-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}

.popup-code {
    background-color: #1e2336;
    color: #ffbe00;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    width: 30vw;
    min-width:300px
}

.popup-title {
    color: #ffbe00;
    font-size: max(0.8vw, 14px);
}

.input-field {
    margin-top: 3vh;
    padding: 2vh;
    width: 80%;
}

.close-button {
    margin-top: 20px;
    z-index: 99999;
}

.popup-submit {
    margin-top: 3vh;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: transparent;
    color: #ffbe00;
    border: none;
    outline: none;
    text-align: center;
    text-decoration: none;
    font-size: max(0.8vw, 14px);
    transition-duration: 0.4s;
    width: 50%;
}

.popup-submit:hover {
    background-color: #ffbe00;
    color: #1e2336;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 0;
    border-top: 4px solid #ffbe00;
    animation: spin 1s infinite linear;
    margin-top: 2vh;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}