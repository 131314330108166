@font-face {
  font-family: roboto_light;
  src: url(Roboto-Light.ttf);
}

@font-face {
  font-family: roboto_Black;
  src: url(Roboto-Black.ttf);
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: roboto_light, Arial, sans-serif;
  letter-spacing: 2px;
}

.home {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1e2336;
  padding: 20px;
  color: #ffbe00;
}

.chut {
  color: #1e2336;
}

.content-container {
  width: 50%;
  min-width: 300px;
}

.centered-text {
  font-size: max(0.8vw, 14px);
}

.bottom-text {
  margin-top: 2vh;
  font-size: max(0.8vw, 14px);
}

.image-container {
  display: flex;
  justify-content: center;
  margin-top: 2vh;
}

.image-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5vw;
}

.rotating-image {
  flex-shrink: 0;
  width: 5vw;
  min-width: 40px;
  height: auto;
  animation: rotate 5s linear infinite;
}

.rotating-image-reverse {
  flex-shrink: 0;
  width: 5vw;
  min-width: 40px;
  height: auto;
  animation: rotate-reverse 5s linear infinite;
}

.image-text {
  margin-top: 2vh;
  font-size: max(0.8vw, 14px);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-reverse {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
}

.custom-button {
  border-radius: 5px;
  background-color: transparent;
  color: #ffbe00;
  border: none;
  outline: none;
  padding: 14px 24px;
  text-align: center;
  text-decoration: none;
  font-size: max(0.8vw, 14px);
  transition-duration: 0.4s;
  margin-right: 10vw;
  width: 10vw;
  min-width: 150px;
  letter-spacing: 2px;
}

.custom-button:hover {
  background-color: #ffbe00;
  ;
  color: #1e2336;
}

.image-group {
  display: flex;
  align-items: center;
}

.logo-social {
  width: 2vw;
  min-width: 20px;
  margin-right: 5vw;
}

.popup {
  position: fixed;
  bottom: 5vh;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(40, 40, 40, 0.9);
  border-radius: 10px;
  padding: 2vh;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(20vh);
  transition: opacity 1s ease, transform 1s ease;
  font-size: 0.9vw;
}

.popup.show {
  opacity: 1;
  transform: translateY(0);
}

@keyframes fadeUp {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.button-group {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.popup-buttons {
  margin-top: 20px;
}

.popup button {
  border-radius: 5px;
  font-family: roboto_light, Arial, sans-serif;
  background-color: transparent;
  color: #ffbe00;
  border: none;
  outline: none;
  text-align: center;
  text-decoration: none;
  font-size: 0.9vw;
  transition-duration: 0.4s;
  width: 5vw;
  height: 3vh;
  margin-left: 2vw;
}

.popup button:hover,
.popup button:focus {
  box-shadow: 0 0 7px 2px #1c1b1b;
  color: #333;
  background-color: #ffbe00;
}