html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: roboto_light, Arial, sans-serif;
}

.le_musee {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1e2336;
    padding: 20px;
    color: white;
    overflow: auto;
}

.carousel {
  width: 100%;
  min-width: 400px;
  z-index: 1;
}

.custom-caption {
  position:static!important;
}

.caption-text {
  color: #ffbe00;
}

.spinner_musee {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 0;
  border-top: 4px solid #ffbe00;
  animation: spin 1s infinite linear;
  margin-top: 2vh;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}