.app {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
}

.menu {
  display: flex;
  gap: 2vh;
  background-color: transparent;
  position: absolute;
  top: 1.5vh;
  left: 1.5vw;
  z-index: 9999;
}

.menu img {
  max-width: 1.5vw;
  width: 1.5vw;
  min-width: 20px;
}

.grecaptcha-badge { 
  visibility: hidden;
}