html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.classement {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1e2336;
    padding: 20px;
    color: white;
    overflow: auto;
}
