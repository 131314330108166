.table-container {
  max-width: 65%;
  width: 65%;
  min-width: 400px;
  height: 65vh;
  overflow: auto;
  z-index: 1;
}

.leaderboard-results {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

.leaderboard-results thead th {
  padding: 2.5vh;
  color: #747474;
  font-size: max(1vw,12px);
  font-weight: bold;
  text-align: center;
  position: sticky;
  top: 0;
  background-color: #1a1a1a;
  z-index: 12;
}

.leaderboard-results tbody td {
  padding: 2vh;
  background: #1a1a1a;
  font-size: max(0.8vw, 12px);
  border-bottom: 0.5vh solid #232323;
  text-align: center;
  z-index: 10;
}

.table-cell-small {
  width: 20vw;
  min-width:20px;
}

.table-cell-image-endive {
  width: 1.5vw;
}

.table-cell-image-code {
  width: 1.5vw;
}

.table-cell-image-cinoche {
  width: 1.5vw;
}

.table-cell-image-game {
  height: 5vh;
}

.leaderboard-results tbody td:nth-of-type(7) {
  font-weight: bold;
}

.leaderboard-results tbody tr:hover td {
  background: #1d1d1d;
}

.leaderboard-results tbody tr {
  color: #747474;
}

.leaderboard-results tbody tr:first-child {
  color: #ffbe00;
}

.table-container::-webkit-scrollbar {
  width: 0.5em;
}

.table-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2vh;
  width: 65%;
  min-width: 400px;
  height: 10vh;
  border-radius: 1.5vh;
  border: 0.2vh solid #ffbe00;
  z-index: 1;
}

.search-bar input {
  width: 30vw;
  padding: 1vh;
  margin-right: 2vh;
  font-size: max(0.8vw, 14px);
}

.search-bar label {
  margin-right: 5%;
  font-size: max(0.8vw, 14px);
  color: #ffbe00;
}