@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

* {
    margin: 0px;
    padding: 0px;
}

.area {
    background: #1e2336;
    /*background: -webkit-linear-gradient(to left, #d60000, #c84e4e);https://coolors.co/gradients*/
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles img {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    animation: animate 25s linear infinite;
    bottom: -150px;

}

.circles img:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles img:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles img:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles img:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles img:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles img:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles img:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles img:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles img:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles img:nth-child(10) {
    left: 85%;
    width: 30%;
    height: 30%;
    animation-delay: 0s;
    animation-duration: 11s;
}

.circles img:nth-child(11) {
    left: 12%;
    width: 30%;
    height: 30%;
    animation-delay: 0s;
    animation-duration: 20s;
}

.circles img:nth-child(12) {
    left: 43%;
    width: 30%;
    height: 30%;
    animation-delay: 0s;
    animation-duration: 13s;
}

@keyframes animate {

    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

.home-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../images/endive.png'), url('../images/carte-lutin.png'), url('../images/code128.png');
    background-position: center;
    background-size: cover;
    animation: backgroundAnimation 20s linear infinite;
    z-index: 0;
}

@keyframes backgroundAnimation {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 200px 200px;
    }

    100% {
        background-position: -200px -200px;
    }
}